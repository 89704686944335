<template>
	<el-form
		ref="pwdFormRef"
		:model="pwdForm"
		:rules="rules"
		label-width="120px"
		class="change-pwd-form"
		:size="formSize"
	>
		<el-form-item label="原密码" prop="oldPassword">
			<el-input v-model="pwdForm.oldPassword" type="password" />
		</el-form-item>
		<el-form-item label="新密码" prop="newPassword">
			<el-input v-model="pwdForm.newPassword" type="password" />
		</el-form-item>
		<el-form-item label="确认密码" prop="newPassword1">
			<el-input v-model="pwdForm.newPassword1" type="password" />
		</el-form-item>
		<el-form-item class="padding-top-20">
			<el-button type="primary" @click="submitForm(pwdFormRef)">提交</el-button>
			<el-button @click="resetForm(pwdFormRef)">清空</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
import { reactive, ref, defineComponent } from 'vue'
import { updatePassword } from '@/api/system/user'
import md5 from 'js-md5'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/modules/user'
import { useAppStore } from '@/store/modules/app'

export default defineComponent({
	name: 'ChangePwd',
	setup() {
		const router = useRouter()
		const userStore = useUserStore()
		const appStore = useAppStore()
		const formSize = ref('default')
		const pwdFormRef = ref(null)
		const pwdForm = reactive({
			oldPassword: '',
			newPassword: '',
			newPassword1: '',
		})

		const rules = reactive({
			oldPassword: [
				{ required: true, message: '请输入原密码', trigger: 'blur' },
				{ min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
			],
			newPassword: [
				{ required: true, message: '请输入新密码', trigger: 'blur' },
				{ min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
			],
			newPassword1: [
				{ required: true, message: '请输入确认密码', trigger: 'blur' },
				{ min: 6, message: '密码长度不能少于6位', trigger: 'blur' },
			],
		})

		const submitForm = async formEl => {
			if (!formEl) return
			if (pwdForm.newPassword !== pwdForm.newPassword1) {
				ElMessage.error('两次密码输入不一致!')
				return
			}

			await formEl.validate(valid => {
				if (valid) {
					updatePassword(md5(pwdForm.oldPassword), md5(pwdForm.newPassword), appStore.tenantId)
						.then(res => {
							if (res.code === 200) {
								ElMessage.success('修改密码成功')
								setTimeout(() => {
									userStore.logout()
									router.push('/login')
								}, 1000)
							} else {
								ElMessage.error(res.msg)
							}
						})
						.catch(e => {
							ElMessage.error(e.message)
						})
				}
			})
		}

		const resetForm = formEl => {
			if (!formEl) return
			formEl.resetFields()
		}
		return {
			formSize,
			pwdFormRef,
			pwdForm,
			rules,
			submitForm,
			resetForm,
		}
	},
})
</script>
<style lang="less">
.change-pwd-form {
	width: 500px;
	padding-top: 20px;
	.padding-top-20 {
		padding-top: 20px;
	}
}
</style>
