<template>
	<div class="profile">
		<el-tabs v-model="activeName" class="demo-tabs">
			<!-- <el-tab-pane label="个人信息" name="info">
				<my-info></my-info>
			</el-tab-pane> -->
			<el-tab-pane label="修改密码" name="pwd">
				<change-pwd></change-pwd>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { defineComponent, toRefs, reactive } from 'vue'
// import MyInfo from './components/my-info'
import ChangePwd from './components/change-pwd'

export default defineComponent({
	name: 'Profile',
	components: {
		// MyInfo,
		ChangePwd,
	},
	setup() {
		const state = reactive({
			activeName: 'pwd',
		})

		return {
			...toRefs(state),
		}
	},
})
</script>

<style lang="less">
.profile {
	.el-tabs__header {
		padding: 0;
		position: relative;
		margin: 0 0 15px;
	}
	.el-tabs__nav-wrap {
		overflow: hidden;
		margin-bottom: -1px;
		position: relative;
	}
	.el-tabs__nav-scroll {
		overflow: hidden;
	}
	.el-tabs__nav {
		white-space: nowrap;
		position: relative;
		transition: transform var(--el-transition-duration);
		float: left;
		z-index: calc(var(--el-index-normal) + 1);
	}
	.el-tabs__active-bar {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 2px;
		background-color: var(--el-color-primary);
		z-index: 1;
		transition: width var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier),
			transform var(--el-transition-duration) var(--el-transition-function-ease-in-out-bezier);
		list-style: none;
	}
	.el-tabs__item {
		padding: 0 20px;
		height: 40px;
		box-sizing: border-box;
		line-height: 40px;
		display: inline-block;
		list-style: none;
		font-size: 14px;
		font-weight: 500;
		cursor: pointer;
		color: var(--el-text-color-primary);
		position: relative;
	}
	.el-tabs__nav-wrap:after {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 2px;
		background-color: var(--el-border-color-light);
		z-index: var(--el-index-normal);
	}
	.el-tabs__item.is-active {
		color: var(--el-color-primary);
	}
	.el-tabs--top .el-tabs__item.is-top:nth-child(2),
	.el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
	.el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
	.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2) {
		padding-left: 0;
	}
}
</style>
